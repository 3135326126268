import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";
import React from "react";
import Root from "./Root";
import ScrollToTop from "./ScrollToTop";
import festivalImage from "../assets/logos/logo_sunside.png";
import Festival from "./Festival";
import Lineup from "./Lineup";
import Fotos from "./Fotos";
import FotosWrapper from "./FotosWrapper";

export default function RoutesComponent() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path={""} element={<Root/>}>
                    <Route
                        path={""}
                        element={<Festival logo={festivalImage}></Festival>}
                    />
                    <Route path={"/lineup"} element={<Lineup logo={festivalImage} seeVenue={false} seePartner={false} seeTickets={false}/>}/>
                    <Route path={"/fotos"} element={<FotosWrapper logo={festivalImage} seeVenue={false} seePartner={false} seeTickets={false}/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

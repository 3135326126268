import "../styles/Festival.css";

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DarkFooter from "./DarkFooter";
import { Link } from "react-router-dom";
import LineUpCard from "./LineUpCard";
import axios from "axios";
import { WEBSITE_URL } from "./Festival";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowLeft, faArrowRight, faArrowUp, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Fotos from "./Fotos";
import ScrollToTop from "./ScrollToTop";
import ScrollToTopButton from "./ScrollToTopButton";
export interface LineUpProps {
    logo: string;
    seeVenue: boolean;
    seePartner: boolean;
    seeTickets: boolean;
}

const ticketLink = "https://shop.eventjet.at/sunside-festival/event/9420374a-7c7c-404d-8f60-a968903a275a";

export default function FotosWrapper({ logo, seePartner, seeVenue, seeTickets }: LineUpProps) {
    const [isNavbarFixed, setIsNavbarFixed] = useState(true);

    const [dayNumber, setDayNumber] = useState<number |undefined>(undefined);
    const [stageName, setStageName] = useState<"rave" | "paradise" | "hiphop" | "radio" |undefined>(undefined);
    const [numLoad, setNumLoad] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>();
    const [eventFotos, setEventFotos] = useState<Map<number, string[]>>(
        new Map<number, string[]>()
    );
    const hasLoadedRef = useRef(false);
    const [highlightImage, setHighlightImage] = useState<number |undefined>(undefined);

    const imagesPerLoad = 21;
    const numOfLoadsNeeded = useMemo(() => {
        let numImages = 0;
        if(dayNumber === 1){
            if(stageName === "rave"){
                numImages = 133;
            }
            else if(stageName === "paradise"){
                numImages = 116;
            }
            else if (stageName === "hiphop"){
                numImages = 27;
            }
        }
        else if(dayNumber === 2){
            if(stageName === "paradise") {
                numImages = 142;
            }
        }
        else if(dayNumber === 3){
            if(stageName === "rave"){
                numImages = 47;
            }
            else if(stageName === "paradise"){
                numImages = 180;
            }
            else if (stageName === "hiphop"){
                numImages = 23;
            }
            else if(stageName === "radio"){
                numImages = 2;

            }
        }
        return Math.ceil((numImages) / imagesPerLoad) + 1; //TODO
    }, [dayNumber, stageName])

    const loadMoreImages = useCallback((dn: number, sn: string, loadNumber: number) => {
        if (dayNumber !== undefined && stageName !== undefined) {
            console.log(dayNumber);
            console.log(stageName);
            console.log(loadNumber);
            setIsLoading(true);
            const url = WEBSITE_URL + "eventPhotos/sunsidefestival_" + dn + "_" + sn + "/" + loadNumber;
            console.log(url);
            axios
                .get(url)
                .then((response) => {
                    let m = eventFotos;
                    m.set(loadNumber, response.data);
                    console.log(m);
                    setEventFotos(m);
                    setNumLoad(loadNumber + 1);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                });
        }
    }, [dayNumber, eventFotos, stageName]);

    const displayImages = useMemo(() => {
        let imageArray: Array<string> = [];
        for (let i = 0; i < numLoad; i++) {
            eventFotos.get(i)?.forEach((val) => imageArray.push(val));
        }
        return imageArray;
    }, [eventFotos, numLoad]);

    useEffect(() => {
        if (!hasLoadedRef.current && dayNumber !== undefined && stageName !== undefined) {
            hasLoadedRef.current = true;
            loadMoreImages(dayNumber, stageName, numLoad);
        }
    }, [dayNumber, loadMoreImages, numLoad, stageName]);
    console.log(highlightImage);
    console.log(numLoad)
    return (
        <div className={"background"}>
            <ScrollToTopButton />
            {highlightImage !== undefined && (
                <div className={"highlightImageWrapperWrapper"} onClick={() => setHighlightImage(undefined)}>
                    <div className={"highlightImageWrapper"}>
                        {highlightImage > 0 && (
                        <button className={"changeHighlightButton"} onClick={(ev) => {
                            setHighlightImage(highlightImage - 1);
                            ev.stopPropagation(); // Stop propagation here

                        }}>
                            <FontAwesomeIcon className={"highlightButtonIcon"} icon={faArrowLeft}/>
                        </button>
                        )}
                        <img className="highlightImage" src={`data:image/jpeg;base64,${displayImages[highlightImage]}`}
                             onClick={(ev) => ev.stopPropagation()}/>
                        {(highlightImage < 21 * (numLoad -1) - 1) && (
                            <button className={"changeHighlightButton"} onClick={(ev) => {
                                setHighlightImage(highlightImage + 1);
                                ev.stopPropagation(); // Stop propagation here
                                }}>
                                    <FontAwesomeIcon className={"highlightButtonIcon"} icon={faArrowRight}/>
                            </button>
                        )}
                    </div>
                    <button className={"changeHighlightButton"} onClick={(ev) => {
                        setHighlightImage(undefined);
                        ev.stopPropagation(); // Stop propagation here
                    }} style={{margin: "0"}}>
                        Zurück
                    </button>
                </div>
            )}
            <div className={isNavbarFixed ? "navbar sticky" : "navbar"}>
                <div className={"navs"}>
                    <Link to={"/"} className={"navImageWrapper"}>
                        <img src={logo} className={"navImage"} alt={"Sunside"}/>
                    </Link>
                    <Link className={"nav"} to={"/lineup"}>
                        LINEUP
                    </Link>
                    <Link to={{pathname: "/", search: "?programm"}} className={"nav"}>
                        PROGRAMM
                    </Link>
                    {seeTickets && (
                        <Link className={"nav link"} to={ticketLink} target="_blank">
                            TICKETS
                        </Link>
                    )}
                    {seeVenue && (
                        <Link to={{pathname: "/", search: "?venue"}} className={"nav"}>
                            VENUE
                        </Link>
                    )}
                    <Link to={{pathname: "/", search: "?anreise"}} className={"nav"}>
                        ANREISE
                    </Link>
                    <Link to={{pathname: "/", search: "?kontakt"}} className={"nav"}>
                        KONTAKT
                    </Link>
                    {seePartner && (
                        <Link to={{pathname: "/", search: "?partner"}} className={"nav"}>
                            PARTNER
                        </Link>
                    )}
                    <Link className={"nav selected"} to={"/fotos"}>
                        FOTOS
                    </Link>
                    <Link to={"https://www.instagram.com/sunside.festival/"} target={"_blank"} className={"nav"}>
                        <img src={"festivalAssets/instagram-white-icon.png"} className={"socialNav"}/>
                    </Link>
                    <Link to={"https://www.facebook.com/sunside.festival"} target={"_blank"} className={"nav"}>
                        <img src={"festivalAssets/facebook-app-round-white-icon.png"} className={"socialNav"}/>
                    </Link>
                </div>
            </div>
            <div
                className={!isNavbarFixed ? "navbarGhost none" : "navbarGhost"}
            ></div>
            <Fotos stageName={stageName} setHighlightImage={setHighlightImage} displayImages={displayImages} isLoading={isLoading} loadMoreImages={loadMoreImages} numLoad={numLoad} setDayNumber={setDayNumber} setNumLoad={setNumLoad} dayNumber={dayNumber} numOfLoadsNeeded={numOfLoadsNeeded} setStageName={setStageName} seePartner={false} logo={""} seeVenue={false}></Fotos>
    <DarkFooter footerTab={"none"}/>
</div>
)
    ;
}

import "../styles/Festival.css";

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DarkFooter from "./DarkFooter";
import { Link } from "react-router-dom";
import LineUpCard from "./LineUpCard";
import axios from "axios";
import { WEBSITE_URL } from "./Festival";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faSpinner } from "@fortawesome/free-solid-svg-icons";
export interface FotosProps {
    logo: string;
    seeVenue: boolean;
    seePartner: boolean;
    dayNumber?: number;
    stageName?: string;
    numLoad: number;
    setDayNumber: (num: number) => void;
    setStageName: (name: "paradise" | "rave" | "hiphop" | "radio" | undefined) => void;
    setNumLoad: (num: number) => void;
    loadMoreImages: (num: number, name: string, loadNumber: number) => void;
    isLoading?: boolean;
    numOfLoadsNeeded: number;
    displayImages: string[];
    setHighlightImage: (index: number) => void;
}

export default function Fotos({ displayImages , setHighlightImage, stageName, numLoad, dayNumber,numOfLoadsNeeded, setDayNumber, setStageName, setNumLoad, loadMoreImages, isLoading }: FotosProps) {
    const [dayOneHovered, setDayOneHovered] = useState<boolean>();
    const [dayTwoHovered, setDayTwoHovered] = useState<boolean>();
    const [dayThreeHovered, setDayThreeHovered] = useState<boolean>();
    const [dayOneOpened, setDayOneOpened] = useState<boolean>();
    const [dayTwoOpened, setDayTwoOpened] = useState<boolean>();
    const [dayThreeOpened, setDayThreeOpened] = useState<boolean>();

    const scrollToFotos = useCallback(() => {
        const element = document.getElementById("fotosHeader");
        let y = element!.getBoundingClientRect().top + window.pageYOffset;
        y -= 100;
        window.scrollTo({ top: y, behavior: "smooth" });
    },[]);

    return (
        <div className={"fotosWrapper"}>
            <div className={"fotoDayWrapper one"} onMouseEnter={() => setDayOneHovered(true)}
                 onMouseLeave={() => setDayOneHovered(false)}>
                <div className={"fotoDayHeader"} onClick={() => {
                    setDayOneOpened(!dayOneOpened);
                }}>
                    <div>Tag 1: 30.04</div>
                    <div>
                        <FontAwesomeIcon icon={faArrowDown} size={"sm"}
                                         className={`fotoHeaderArrow ${(dayOneHovered || dayOneOpened || dayNumber === 1) ? "selected" : ""}`}/>
                    </div>
                </div>
                <div className={`fotoDayFotos ${(dayOneHovered || dayOneOpened || dayNumber === 1) ? "opened" : ""}`}>
                    <div className={`fotoDayFotosWrapper`}>
                        <img
                            className={`fotoDayFoto ${(dayNumber === 1 && stageName === "paradise") ? "selected" : ""}`}
                            src={"festivalAssets/SUNSIDE_website_30.4.-paradise.jpg"} onClick={() => {
                            setDayNumber(1);
                            setStageName("paradise");
                            setNumLoad(1);
                            loadMoreImages(1, "paradise", 1);
                            scrollToFotos();
                        }}/>
                        <img
                            className={`fotoDayFoto ${(dayNumber === 1 && stageName === "rave") ? "selected" : ""}`}
                            src={"festivalAssets/SUNSIDE_website_30.4.-raveroom.jpg"} onClick={() => {
                            setDayNumber(1);
                            setStageName("rave");
                            setNumLoad(1);
                            loadMoreImages(1, "rave", 1);
                            scrollToFotos();
                        }}/>
                        <img
                            className={`fotoDayFoto ${(dayNumber === 1 && stageName === "hiphop") ? "selected" : ""}`}
                            src={"festivalAssets/SUNSIDE_website_30.4.-hiphop.jpg"} onClick={() => {
                            setDayNumber(1);
                            setStageName("hiphop");
                            setNumLoad(1);
                            loadMoreImages(1, "hiphop", 1);
                            scrollToFotos();
                        }}/>
                    </div>
                </div>
            </div>
            <div className={"fotoDayWrapper two"} onMouseEnter={() => setDayTwoHovered(true)}
                 onMouseLeave={() => setDayTwoHovered(false)}>
                <div className={"fotoDayHeader"} onClick={() => {
                    setDayTwoOpened(!dayTwoOpened);
                }}>
                    <div>Tag 2: 03.05</div>
                    <div>
                        <FontAwesomeIcon icon={faArrowDown} size={"sm"}
                                         className={`fotoHeaderArrow ${(dayTwoHovered || dayTwoOpened || dayNumber === 2) ? "selected" : ""}`}/>
                    </div>
                </div>
                <div className={`fotoDayFotos ${(dayTwoHovered || dayTwoOpened || dayNumber === 2) ? "opened" : ""}`}>
                    <div className={`fotoDayFotosWrapper`}>
                        <img
                            className={`fotoDayFoto ${(dayNumber === 2 && stageName === "paradise") ? "selected" : ""}`}
                            src={"festivalAssets/SUNSIDE_website_3.5.-all.jpg"} onClick={() => {
                            setDayNumber(2);
                            setStageName("paradise");
                            setNumLoad(1);
                            loadMoreImages(2, "paradise", 1);
                            scrollToFotos();

                        }}/>
                    </div>
                </div>
            </div>
            <div className={"fotoDayWrapper three"} onMouseEnter={() => setDayThreeHovered(true)}
                 onMouseLeave={() => setDayThreeHovered(false)}>
                <div className={"fotoDayHeader"} onClick={() => {
                    setDayThreeOpened(!dayThreeOpened);
                }}>
                    <div>Tag 3: 04.05</div>
                    <div>
                        <FontAwesomeIcon icon={faArrowDown} size={"sm"}
                                         className={`fotoHeaderArrow ${(dayThreeHovered || dayThreeOpened || dayNumber === 3) ? "selected" : ""}`}/>
                    </div>
                </div>
                <div
                    className={`fotoDayFotos ${(dayThreeHovered || dayThreeOpened || dayNumber === 3) ? "opened" : ""}`}>
                    <div className={`fotoDayFotosWrapper`}>
                        <img
                            className={`fotoDayFoto ${(dayNumber === 3 && stageName === "paradise") ? "selected" : ""}`}
                            src={"festivalAssets/SUNSIDE_website_4.5.-paradise.jpg"} onClick={() => {
                            setDayNumber(3);
                            setStageName("paradise");
                            setNumLoad(1);
                            loadMoreImages(3, "paradise", 1);
                            scrollToFotos();
                        }}/>
                        <img
                            className={`fotoDayFoto ${(dayNumber === 3 && stageName === "rave") ? "selected" : ""}`}
                            src={"festivalAssets/SUNSIDE_website_4.5.-raveroom.jpg"} onClick={() => {
                            setDayNumber(3);
                            setStageName("rave");
                            setNumLoad(1);
                            loadMoreImages(3, "rave", 1);
                            scrollToFotos();

                        }}/>
                        <img
                            className={`fotoDayFoto ${(dayNumber === 3 && stageName === "hiphop") ? "selected" : ""}`}
                            src={"festivalAssets/SUNSIDE_website_4.5.-hiphop.jpg"} onClick={() => {
                            setDayNumber(3);
                            setStageName("hiphop");
                            setNumLoad(1);
                            loadMoreImages(3, "hiphop", 1);
                            scrollToFotos();

                        }}/>
                        <img
                            className={`fotoDayFoto ${(dayNumber === 3 && stageName === "radio") ? "selected" : ""}`}
                            src={"festivalAssets/SUNSIDE_website_4.5.-rockfloor.jpg"} onClick={() => {
                            setDayNumber(3);
                            setStageName("radio");
                            setNumLoad(1);
                            loadMoreImages(3, "radio", 1);
                            scrollToFotos();
                        }}/>
                    </div>
                </div>
            </div>
            <div className={"loadedFotosWrapper"} id={"fotosHeader"}>
                {(dayNumber !== undefined && stageName !== undefined) && (
                    <div className={"loadedFotosInnerWrapper"}>
                        <div className={"loadedFotosWrapperHeader"}>Fotos:
                        </div>
                        <div className={"photoGridWrapper"}>
                            <div className={"photoGrid"}>
                                {displayImages.map((value, index) => (
                                    <img
                                        className={"eventImagePhoto"}
                                        src={`data:image/jpeg;base64,${value}`}
                                        key={index}
                                        id={"" + index + 1}
                                        onClick={() => setHighlightImage(index)}
                                    ></img>
                                ))}
                            </div>
                            {isLoading && (
                                <div style={{marginTop: "0.5rem", display: "flex", justifyContent: "center"}}>
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        spin={true}
                                        size={"2xl"}
                                    />
                                </div>
                            )}
                            {numLoad < numOfLoadsNeeded && (
                                <button
                                    disabled={isLoading}
                                    onClick={() => loadMoreImages(dayNumber, stageName, numLoad)}
                                    className={"werdePartnerButton"}
                                    style={{marginBottom: "1rem", marginTop: "1rem"}}
                                >
                                    Weitere Bilder laden
                                </button>
                            )}
                        </div>
                    </div>

                )}
            </div>

        </div>
    );
}

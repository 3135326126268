import React, { useState, useEffect } from 'react';
import CircularSlider from '@fseehawer/react-circular-slider';

interface Props {
    targetDate: string; // Target date and time in string format
}

interface Time {
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
}


const CountdownTimer: React.FC<Props> = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const difference = new Date(targetDate).getTime() - new Date().getTime();
        let timeLeft: Time = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (

        <div className={"festivalCountdownWrapper"}>
            <div className={"festivalCountdown"}>
                <div className={"festivalCountdownInner"}>
                    <div>
                        <CircularSlider
                            onChange={() => {} }
                            dataIndex={timeLeft.days}
                            hideKnob={true}
                            min={0}
                            max={45}
                            progressSize={12}
                            trackSize={6}
                            trackDraggable={false}
                            knobDraggable={false}
                            labelBottom={true}
                            label={"Tage"}
                            progressColorFrom="#2DBDEE"
                            progressColorTo="#2DBDEE"
                            trackColor="#333333"
                            labelColor={"black"}
                            knobSize={0}
                        />
                    </div>
                    <div>
                        <CircularSlider
                            onChange={() => {} }
                            dataIndex={timeLeft.hours}
                            hideKnob={true}
                            min={0}
                            max={24}
                            progressSize={12}
                            trackSize={6}
                            trackDraggable={false}
                            knobDraggable={false}
                            labelBottom={true}
                            label={"Stunden"}
                            progressColorFrom="#2DBDEE"
                            progressColorTo="#2DBDEE"
                            trackColor="#333333"
                            labelColor={"black"}
                        />
                    </div>
                    <div>
                        <CircularSlider
                            onChange={() => {} }
                            dataIndex={timeLeft.minutes}
                            hideKnob={true}
                            min={0}
                            max={60}
                            progressSize={12}
                            trackSize={6}
                            trackDraggable={false}
                            knobDraggable={false}
                            labelBottom={true}
                            label={"Minuten"}
                            progressColorFrom="#2DBDEE"
                            progressColorTo="#2DBDEE"
                            trackColor="#333333"
                            labelColor={"black"}
                        />
                    </div>
                    <div>
                        <CircularSlider
                            onChange={() => {} }
                            dataIndex={timeLeft.seconds}
                            hideKnob={true}
                            min={0}
                            max={60}
                            progressSize={12}
                            trackSize={6}
                            trackDraggable={false}
                            knobDraggable={false}
                            labelBottom={true}
                            label={"Sekunden"}
                            progressColorFrom="#2DBDEE"
                            progressColorTo="#2DBDEE"
                            trackColor="#333333"
                            labelColor={"black"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CountdownTimer;

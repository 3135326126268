import "../styles/Festival.css";
import "../styles/Card.css";

import React, { useState } from "react";
export interface LineUpCardProps {
    frontPageImage: string;
    djName: string;
    playDate: string;
    playArea: string;
    test?: boolean;
}


export default function LineUpCard({ frontPageImage, playArea, playDate, djName, test=false }: LineUpCardProps) {
    const [isFlipped, setIsFlipped] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleCardFlip = () => {
            setIsFlipped(!isFlipped);
    };

    const handleClickFlip = () => {
        setIsClicked(!isClicked);
    }
    const getText = () => {
        return "Clicked " + isClicked;
    }
    const getTextT = () => {
        return "Flipped " + isFlipped;
    }
    return (

        <div className={`lineupCard ${isFlipped ? 'flipped' : ''} ${isClicked ? 'clicked' : ''}`} onMouseEnter={handleCardFlip} onMouseLeave={handleCardFlip} onClick={handleClickFlip}>
            {test && (
                <>
                    <div>{getText()}</div>
                    <div>{getTextT()}</div>
                </>
            )}
            <div className="card-inner">
                <div className="card-front">
                    <img src={frontPageImage} alt={djName} />
                </div>
                <div className="card-back">
                    <img src="/artistAssets/backside.jpg" alt="Infos" />
                    <div className={"djName"}>{djName}</div>
                    <div className={"djDate"}>{playDate}</div>
                    <div className={"djArea"}>{playArea}</div>
                </div>

            </div>
        </div>
    );
}

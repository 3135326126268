import "../styles/Festival.css";

import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import CustomMap from "./CustomMap";
import DarkFooter from "./DarkFooter";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CountdownTimer from "./CountdownTimer";
import axios from "axios";

export const WEBSITE_URL = "https://hosting190127.a2f57.netcup.net/";


export interface FestivalProps {
  logo: string;
}
const navs = ["programm","venue","anreise","partner","kontakt"];
const ticketLink = "https://shop.eventjet.at/sunside-festival/event/9420374a-7c7c-404d-8f60-a968903a275a";

export default function Festival({ logo }: FestivalProps) {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.post(WEBSITE_URL + "send-email-festival", formData).then(() => {
        alert("Email wurde erfolgreich versendet!");
      }).catch(() => {
        alert("Ein Fehler ist aufgetreten!");
      });
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Error sending email");
    }
  };

  let element = document.getElementById("titleImage");

  let partner = document.getElementById("partner");
  let anreise = document.getElementById("anreise");
  let programm = document.getElementById("programm");
  let venue = document.getElementById("venue");
  let kontakt = document.getElementById("kontakt");

  const seeVenue = false;
  const seePartner = false;
  const seeTickets = false;

  const handleScrollTo = (
    tab:  "programm" | "venue" | "anreise" | "partner" | "kontakt" | string
  ) => {
    let y;
    switch (tab) {
      case "programm":
        y = programm!.getBoundingClientRect().top + window.pageYOffset;
        break;
      case "anreise":
        y = anreise!.getBoundingClientRect().top + window.pageYOffset;
        break;
      case "partner":
        y = partner!.getBoundingClientRect().top + window.pageYOffset;
        break;
      case "kontakt":
        y = kontakt!.getBoundingClientRect().top + window.pageYOffset;
        break;
      default:
        y = 0;
    }
    y -= 100;
    window.scrollTo({ top: y, behavior: "smooth" });
  };


  useEffect(() => {
    let element = document.getElementById("titleImage");
    anreise = document.getElementById("anreise");
    programm = document.getElementById("programm");
    partner = document.getElementById("partner");
    kontakt = document.getElementById("kontakt");

    const handleScroll = () => {
      const offset = window.scrollY;
      setIsNavbarFixed(offset >= element!.getBoundingClientRect().height);
    };
      let searchParam = window.location.search;
      searchParam = searchParam.substring(1);
      if (navs.includes(searchParam)) {
        handleScrollTo(searchParam);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={"background"}>
      <div className={"titleImageWrapper"}>
        <img
          src={"festivalAssets/titelgrafik_website-seaside_desktop.jpg"}
          className={"titleImage"}
          id={"titleImage"}
        ></img>
        <CountdownTimer targetDate="2024-05-03T21:00:00" />
      </div>
      <div className={isNavbarFixed ? "navbar sticky" : "navbar"}>
        <div className={"navs"}>
          <Link to={"/"} className={"navImageWrapper"}>
            <img src={logo} className={"navImage"} alt={"Sunside"}/>
          </Link>
          <Link className={"nav"} to={"/lineup"}>
            LINEUP
          </Link>
          <div className={"nav"} onClick={() => handleScrollTo("programm")}>
            PROGRAMM
          </div>
          {seeTickets && (
              <Link className={"nav link"} to={ticketLink} target="_blank">
                TICKETS
              </Link>
          )}
          {seeVenue && (
              <div className={"nav"} onClick={() => handleScrollTo("venue")}>
                VENUE
              </div>
          )}
          <div className={"nav"} onClick={() => handleScrollTo("anreise")}>
            ANREISE
          </div>
          {seePartner && (
              <div className={"nav"} onClick={() => handleScrollTo("partner")}>
                PARTNER
              </div>
          )}
          <div className={"nav"} onClick={() => handleScrollTo("kontakt")}>
            KONTAKT
          </div>
          <Link className={"nav"} to={"/fotos"}>
            FOTOS
          </Link>
          <Link to={"https://www.instagram.com/sunside.festival/"} target={"_blank"} className={"nav"}>
            <img src={"festivalAssets/instagram-white-icon.png"} className={"socialNav"} />
          </Link>
          <Link to={"https://www.facebook.com/sunside.festival"} target={"_blank"} className={"nav"}>
            <img src={"festivalAssets/facebook-app-round-white-icon.png"} className={"socialNav"} />
          </Link>
        </div>
      </div>
      <div
        className={!isNavbarFixed ? "navbarGhost none" : "navbarGhost"}
      ></div>
      <div className={"wrapper programmWrapper"}>
        <div className={"tabHeader"} id={"programm"}>
          <div className="circle leftCircle"></div>
          Programm
          <div className="circle rightCircle"></div>
        </div>
        <div className={"programmImageWrapper"}>
          <img
            src={"festivalAssets/sunside_programm.jpg"}
            className={"programmImage"}
          />
        </div>
      </div>
      <div className={"wrapper ticketWrapper"}>
        <div className={"tabHeader"}>
          <div className="circle leftCircle"></div>
          <div className={"tabHeaderText"}>GET YOUR TICKETS HERE</div>
          <div className="circle rightCircle"></div>
        </div>
        <Link to={ticketLink} target={"_blank"}>
          <button className={"ticketButton"}>TICKETS</button>
        </Link>
      </div>
      {seeVenue && (
          <div className={"wrapper venueWrapper"}>
            <div className={"tabHeader"} id={"venue"}>
              <div className="circle leftCircle"></div>
              VENUE
              <div className="circle rightCircle"></div>
            </div>
          </div>
      )}
      <div className={"wrapper anreiseWrapper"}>
        <div className={"tabHeader"} id={"anreise"}>
          <div className="circle leftCircle"></div>
          ANREISE
          <div className="circle rightCircle"></div>
        </div>
        <div className={"mapsWrapper"}>
          <CustomMap />
        </div>
      </div>
      <div className={"wrapper kontaktWrapper"}>
        <div className={"tabHeader"} id={"kontakt"}>
          <div className="circle leftCircle"></div>
          KONTAKT
          <div className="circle rightCircle"></div>
        </div>
        <div className={"contactWrapper"}>
          <div className={"contactCard"}>
            <div className={"contactHeader"}>
              ANFRAGEN (VIP & ALLGEMEINES)
            </div>
            <div className={"eventFormWrapper"}>
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <div className={"eventFormRow splitRow"}>
                  <div className={"eventFormCol"}>
                    <label htmlFor="name" className={"label"}>Name:</label>
                    <input
                        className={"input-field"}
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                  </div>
                  <div className={"eventFormCol"}>
                    <label htmlFor="email" className={"label"}>Email:</label>
                    <input
                        className={"input-field"}
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                  </div>
                </div>
                <div className={"eventFormRow"}>
                  <label htmlFor="message" className={"label"}>Nachricht:</label>
                  <textarea
                      className={"input-field"}
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                  />
                </div>
                <button type="submit" className={"ticketButton mail"}>Anfrage senden</button>
              </form>
            </div>
          </div>
          <div className={"contactCard"}>
            <div className={"contactHeader"}>KONTAKTDATEN</div>
            <div className={"contactInfo"}>
              <a href="mailto:office@sunsidefestival.at	">office@sunsidefestival.at	</a>
            </div>
          </div>
        </div>
      </div>
      {seePartner && (
          <div className={"wrapper partnerWrapper"}>
            <div className={"tabHeader"} id={"partner"}>
              <div className="circle leftCircle"></div>
              UNSERE PARTNER
              <div className="circle rightCircle"></div>
            </div>
            <div className={"partnerSliderWrapper"}>
              <Carousel
                  showStatus={false}
                  showThumbs={false}
                  className={"imageCarousel"}
                  autoPlay={false}
                  axis={"horizontal"}
                  showArrows={true}
                  stopOnHover
                  infiniteLoop={true}
                  renderArrowPrev={(clickHandler, hasPrev) => {
                    return (
                        <div
                            className={`${hasPrev ? "absolute" : "hidden"} arrow`}
                            onClick={clickHandler}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} color={"black"} />
                        </div>
                    );
                  }}
                  renderArrowNext={(clickHandler, hasNext) => {
                    return (
                        <div
                            className={`${hasNext ? "absolute" : "hidden"} arrow right`}
                            onClick={clickHandler}
                        >
                          <FontAwesomeIcon icon={faArrowRight} color={"black"} />
                        </div>
                    );
                  }}
              >
                <div className={"partnerRow"}>
                  <img
                      src={"/partner_logos/bacardi-png.png"}
                      className={"partnerLogo"}
                  />
                  <img
                      className={"partnerLogo"}
                      src={"/partner_logos/dropit_black.png"}
                  />
                  <img
                      className={"partnerLogo"}
                      src={"/partner_logos/podo_transparent.png"}
                  />
                </div>
                <div className={"partnerRow"}>
                  <img className={"partnerLogo"} src={"/partner_logos/zonex.png"} />
                  <img
                      className={"partnerLogo"}
                      src={"/partner_logos/sol-png.png"}
                  />
                  <img
                      className={"partnerLogo"}
                      src={"/partner_logos/redbull-png.png"}
                  />
                </div>
                <div className={"partnerRow"}>
                  <img
                      className={"partnerLogo"}
                      src={"/partner_logos/eristoff_png.png"}
                  />
                  <img
                      className={"partnerLogo"}
                      src={"/partner_logos/heineken_png.png"}
                  />
                  <img
                      className={"partnerLogo"}
                      src={"/partner_logos/AC_staff_v2.png"}
                  />
                </div>
              </Carousel>
            </div>
          </div>
      )}
      <DarkFooter footerTab={"none"} />
    </div>
  );
}

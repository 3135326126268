import "../styles/Festival.css";

import React, { useState } from "react";
import DarkFooter from "./DarkFooter";
import { Link } from "react-router-dom";
import LineUpCard from "./LineUpCard";
import { LineUpProps } from "./FotosWrapper";

const ticketLink = "https://shop.eventjet.at/sunside-festival/event/9420374a-7c7c-404d-8f60-a968903a275a";

const dreißigArpil = "30.4.2024";

const dritterMai = "03.05.2024";
const vierterMai = "04.05.2024";

const flashback = "FLASHBACK";
const paradise = "PARADISE CLUB";
const rave = "RAVE ROOM";
const hiphop = "HIP HOP FLOOR"
const stadl = "BEACH STADL";

export default function Lineup({ logo, seePartner, seeVenue, seeTickets }: LineUpProps) {
    const [isNavbarFixed, setIsNavbarFixed] = useState(true);

    return (
        <div className={"background"}>
            <div className={isNavbarFixed ? "navbar sticky" : "navbar"}>
                <div className={"navs"}>
                    <Link to={"/"} className={"navImageWrapper"}>
                        <img src={logo} className={"navImage"} alt={"Sunside"}/>
                    </Link>
                    <Link className={"nav selected"} to={"/lineup"}>
                        LINEUP
                    </Link>
                    <Link to={{ pathname: "/", search: "?programm" }} className={"nav"}>
                        PROGRAMM
                    </Link>
                    {seeTickets && (
                        <Link className={"nav link"} to={ticketLink} target="_blank">
                            TICKETS
                        </Link>
                    )}
                    {seeVenue && (
                        <Link to={{ pathname: "/", search: "?venue" }} className={"nav"}>
                            VENUE
                        </Link>
                    )}
                    <Link to={{ pathname: "/", search: "?anreise" }} className={"nav"}>
                        ANREISE
                    </Link>
                    <Link to={{ pathname: "/", search: "?kontakt" }} className={"nav"}>
                        KONTAKT
                    </Link>
                    {seePartner && (
                        <Link to={{ pathname: "/", search: "?partner" }} className={"nav"}>
                            PARTNER
                        </Link>
                    )}
                    <Link className={"nav"} to={"/fotos"}>
                        FOTOS
                    </Link>
                    <Link to={"https://www.instagram.com/sunside.festival/"} target={"_blank"} className={"nav"}>
                        <img src={"festivalAssets/instagram-white-icon.png"} className={"socialNav"} />
                    </Link>
                    <Link to={"https://www.facebook.com/sunside.festival"} target={"_blank"} className={"nav"}>
                        <img src={"festivalAssets/facebook-app-round-white-icon.png"} className={"socialNav"} />
                    </Link>
                </div>
            </div>
            <div
                className={!isNavbarFixed ? "navbarGhost none" : "navbarGhost"}
            ></div>

            <div className={"wrapper lineupWrapper"}>
                <div className={"tabHeader lineup"} id={"lineup"}>
                    <div className="circle leftCircle"></div>
                    <div className={"tabHeaderText"}>LINEUP</div>
                    <div className="circle rightCircle"></div>
                </div>
                <div className={"clickInfoWrapper"}>
                    <div>KLICK {" "}
                        <div className={"clickInfoHoverInfo"}> / HOVER</div>
                        {" "}AUF DEN ARTIST FÜR GENAUERE INFOS ZUM AUFTRITT</div>
                </div>
                <div className={"lineupInnerWrapper"}>
                    <LineUpCard djName={"FÄT TONY"} playArea={paradise} playDate={dreißigArpil} frontPageImage={"/artistAssets/tony_1.jpg"} />
                    <LineUpCard djName={"SUBSURFACE"} playArea={paradise} playDate={vierterMai} frontPageImage={"/artistAssets/subsurface_1.jpg"} />
                    <LineUpCard djName={"YOUPHORIA"} playArea={rave} playDate={dreißigArpil} frontPageImage={"/artistAssets/youphoria_1.jpg"} />

                    <LineUpCard djName={"TYO"} playArea={paradise} playDate={dreißigArpil} frontPageImage={"/artistAssets/tyo_1.jpg"} />
                    <LineUpCard djName={"FEBRATION"} playArea={paradise} playDate={vierterMai} frontPageImage={"/artistAssets/febration_1.jpg"} />
                    <LineUpCard djName={"SAMDMA"} playArea={rave} playDate={vierterMai} frontPageImage={"/artistAssets/samdma_1.jpg"} />

                    <LineUpCard djName={"MOFES"} playArea={rave} playDate={dreißigArpil} frontPageImage={"/artistAssets/mofes_1.jpg"} />
                    <LineUpCard djName={"HOUSEVERSTAND"} playArea={paradise} playDate={vierterMai} frontPageImage={"/artistAssets/housi_1.jpg"} />
                    <LineUpCard djName={"PAUL MILE"} playArea={rave} playDate={vierterMai} frontPageImage={"/artistAssets/pauli_1.jpg"} />

                    <LineUpCard djName={"DADO EDUARDO"} playArea={hiphop} playDate={dreißigArpil} frontPageImage={"/artistAssets/dado_1.jpg"} />
                    <LineUpCard djName={"KOFI"} playArea={flashback} playDate={dritterMai} frontPageImage={"/artistAssets/kofi_1.jpg"} />
                    <LineUpCard djName={"TRIFECTA"} playArea={rave} playDate={dreißigArpil} frontPageImage={"/artistAssets/trifecta_1.jpg"} />
                    <LineUpCard djName={"JAYKOW"} playArea={paradise} playDate={vierterMai} frontPageImage={"/artistAssets/jaykow_1.jpg"} />
                    <LineUpCard djName={"DENERIO"} playArea={paradise} playDate={vierterMai} frontPageImage={"/artistAssets/denerio_1.jpg"} />
                    <LineUpCard djName={"LOOTY"} playArea={paradise} playDate={dreißigArpil} frontPageImage={"/artistAssets/looty_1.jpg"} />
                    <LineUpCard djName={"LUCIEN"} playArea={paradise} playDate={dreißigArpil} frontPageImage={"/artistAssets/lucien_1.jpg"} />
                    <LineUpCard djName={"MAARTEN VAN LARSEN"} playArea={stadl} playDate={dritterMai} frontPageImage={"/artistAssets/maartenvanlarsen_1.jpg"} />
                    <LineUpCard djName={"JALIX"} playArea={paradise} playDate={dreißigArpil} frontPageImage={"/artistAssets/jalix_1.jpg"} />
                    <LineUpCard djName={"BERLÜ"} playArea={flashback} playDate={dritterMai} frontPageImage={"/artistAssets/berlue_1.jpg"} />
                    <LineUpCard djName={"MC FLOW"} playArea={rave} playDate={dreißigArpil} frontPageImage={"/artistAssets/mcflow_1.jpg"} />
                    <LineUpCard djName={"GILISTA"} playArea={rave} playDate={vierterMai} frontPageImage={"/artistAssets/gilista_1.jpg"} />
                    <LineUpCard djName={"GUYLIT"} playArea={rave} playDate={vierterMai} frontPageImage={"/artistAssets/guylit_1.jpg"} />
                    <LineUpCard djName={"SHORTY"} playArea={hiphop} playDate={dreißigArpil} frontPageImage={"/artistAssets/shorty_1.jpg"} />
                    <LineUpCard djName={"ACROBASS"} playArea={rave} playDate={dreißigArpil} frontPageImage={"/artistAssets/acrobass_1.jpg"} />
                    <LineUpCard djName={"JANEX"} playArea={rave} playDate={vierterMai} frontPageImage={"/artistAssets/janex_1.jpg"} />
                    <LineUpCard djName={"ELIMENT"} playArea={rave} playDate={dreißigArpil} frontPageImage={"/artistAssets/eliment_1.jpg"} />
                    <LineUpCard djName={"4LIX"} playArea={rave} playDate={dreißigArpil} frontPageImage={"/artistAssets/4lix_1.jpg"}/>
                </div>
            </div>
            <DarkFooter footerTab={"none"} />
        </div>
    );
}

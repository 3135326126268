import React from 'react';
import './App.css';
import MetaPixel from "./utils/meta/metaPixel";
import RoutesComponent from "./layout/Routes";


function App() {
  return (
      <>
        <MetaPixel />
        <RoutesComponent />
      </>
  );
}

export default App;

/*global google*/


import React from "react";
import { GoogleMap, LoadScript, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import markerLogo from "../assets/logos/sunside_pin_3.png";
import mapStyles from "../assets/mapstyles.json";

const center = {
  lat: 47.92923575924281,
  lng: 16.835494069356248,
};

const options = {
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  styles: mapStyles,
};


function CustomMap() {
  return (
      <GoogleMap
          mapContainerClassName={"mapContainer"}
        center={center}
        zoom={12}
        options={options}
      >
        <MarkerF
          position={center}
          icon={{ url: markerLogo, scaledSize: new window.google.maps.Size(110, 110)
        }}
        ></MarkerF>
      </GoogleMap>
  );
}

export default React.memo(CustomMap);

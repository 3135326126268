import "../styles/DarkFooter.css";
import { Link } from "react-router-dom";
import LootyBanner from "../assets/logos/looty_logo.png";

export interface FooterProps {
  footerTab: "impressum" | "datenschutz" | "none";
}

export default function DarkFooter({ footerTab }: FooterProps) {
  return (
    <div className={"footer"}>
      <div className={"footerContent footerContentSplitter"}>
        <Link
          className={
            "link" + " " + (footerTab === "impressum" ? "selected" : "")
          }
          to={"/impressum"}
        >
          Impressum
        </Link>
        <Link
          className={
            "link" + " " + (footerTab === "datenschutz" ? "selected" : "")
          }
          to={"/datenschutz"}
        >
          Datenschutz
        </Link>
      </div>
      <div className={"footerContent"}>
        <span className={"copyright"}>
          Copyright <span className={"span"}>&#169;</span> 2024 <br />
          All rights reserved
        </span>
      </div>
      <div className={"footerContent flex"}>
        <p>Made by</p>
        <a
          href={"https://www.instagram.com/looty.official/"}
          className={"imageHref"}
        >
          <img className={"lootyBanner"} src={LootyBanner} alt={"Looty"} />
        </a>
      </div>
    </div>
  );
}
